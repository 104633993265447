import {defineStore} from 'pinia';
import {StoreModel} from '@/app/ecommerce/services/store-service/interfaces/store.model';
import {StoreService} from '@/app/ecommerce/services/store-service/store.service';

export const useStoreStore = defineStore('store', {
  state() {
    return {
      stores: [] as StoreModel[]
    };
  },
  getters: {
    getStores(state: { stores: StoreModel[] }) {
      return state.stores;
    }
  },
  actions: {
    async fetchStores() {
      this.stores = await StoreService.fetchAllStores();
    },

    async addStore(store: StoreModel) {
      const createdStore = await StoreService.storeStore(store);
      this.stores.unshift(createdStore);
    },

    async editStore(store: StoreModel) {
      const updatedStore = await StoreService.updateStore(store);
      let index = this.stores.map((m) => {
        return m.id;
      }).indexOf(updatedStore.id);
      this.stores[index] = updatedStore;
    }
  }
});
