import {environment} from "@/environment";
import {httpClient} from "@/app/shared/shared-services/httpClient";
import {StoreModel} from "@/app/ecommerce/services/store-service/interfaces/store.model";
import {CreateStoreRequestDto} from "@/app/ecommerce/services/store-service/interfaces/create-store-request.dto";
import {UpdateStoreRequestDto} from "@/app/ecommerce/services/store-service/interfaces/update-store-request.dto";

const URL = `${environment.ecommerceApiBaseUrl}store`;

export const StoreService = {
  async fetchAllStores(): Promise<StoreModel[]> {
    return (await httpClient.get(URL)).data.data;
  },
  async fetchStoreById(storeId: number): Promise<StoreModel> {
    return (await httpClient.get(`${URL}/${storeId}`)).data.data;
  },
  async storeStore(store: StoreModel): Promise<StoreModel> {
    const createStoreRequestDto = this.toCreateRequestDto(store);
    return (await httpClient.post(URL, createStoreRequestDto)).data.data;
  },
  async updateStore(store: StoreModel): Promise<StoreModel> {
    const updateStoreRequestDto = this.toUpdateRequestDto(store);
    return (await httpClient.post(`${URL}/${updateStoreRequestDto.id}?_method=PUT`, updateStoreRequestDto)).data.data;
  },
  toCreateRequestDto(store: StoreModel): CreateStoreRequestDto {
    return {
      name: store.name,
      market_id: store.market.id!,
      hidden: store.hidden,
      cover_url: store.cover_url,
      desc: store.desc
    };
  },
  toUpdateRequestDto(store: StoreModel): UpdateStoreRequestDto {
    return {
      id: store.id!,
      desc: store.desc,
      cover_url: store.cover_url,
      hidden: store.hidden,
      market_id: store.market.id!,
      name: store.name
    };
  }
};
