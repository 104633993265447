
























































































































































import AgTable from '../../../shared/shared-components/ag-grid/AgTable.vue';
import AgTableBtnCell from "@/app/shared/shared-components/ag-grid/AgTableBtnCell.vue";
import AgTableImageCell from '../../../shared/shared-components/ag-grid/AgTableImageCell.vue';
import AgButton from "../../../shared/shared-components/ag-grid/AgButton.vue";
import vSelect from 'vue-select';
import utilities from '@/app/shared/utilities/index';
import Vue from "vue";
import {mapActions, mapState} from "pinia";
import {useStoreStore} from "@/app/ecommerce/views/Stores/store.store";
import {ColDef, GridApi, GridOptions} from "ag-grid-community";
import {StoreModel} from "@/app/ecommerce/services/store-service/interfaces/store.model";
import {MarketModel} from "@/app/ecommerce/services/market-service/interfaces/market.model";
import {useMarketStore} from "@/app/ecommerce/views/Markets/market.store";
import FilePondComponent from "@/app/shared/shared-components/FilePondComponent.vue";
import {VueEditor} from "vue2-editor";

export default Vue.extend({
  name: "Store",
  components: {
    AgTable,
    vSelect,
    FilePondComponent,
    VueEditor
  },
  data() {
    return {
      gridApi: {} as GridApi | null | undefined,
      isLoading: true,
      gridOptions: {} as GridOptions,
      popupActiveDetails: false,
      popupActive: false,
      dialogTitle: '',
      selectedMarketCheck: [(v: number) => !!v || 'Name is required'],
      store: {
        id: undefined,
        name: '',
        cover_url: '',
        market: {} as MarketModel,
        market_id: 0,
        desc: '',
        hidden: false
      } as StoreModel
    };
  },
  computed: {
    ...mapState(useStoreStore, ['getStores']),
    ...mapState(useMarketStore, ['markets'])
  },
  methods: {
    ...mapActions(useStoreStore, ['fetchStores', 'addStore', 'editStore']),
    ...mapActions(useMarketStore, ['fetchMarkets']),
    createColDefs(): Array<ColDef> {
      let self = this;
      return [
        {
          headerName: 'المعرف',
          field: 'id',
          filter: 'agTextColumnFilter',
          suppressSizeToFit: true,
          width: 225,
          headerCheckboxSelection: true,
          checkboxSelection: true,
        },
        {
          headerName: 'الاسم',
          field: 'name',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'اسم المتجر',
          field: 'market.name',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'الشعار',
          field: 'cover_url',
          cellRendererFramework: AgTableImageCell
        },
        {
          headerName: 'مدراء المحل',
          cellRendererFramework: AgButton,
          field: 'id',
          filter: false,
          cellRendererParams: {
            size: 'small',
            color: 'primary',
            name: 'مدراء المحل',
            type: 'gradient',
            click(id: number) {
              self.rowClicked(id);
            }
          }
        },
        {
          headerName: 'الإجراءات',
          field: 'id',
          cellRendererFramework: AgTableBtnCell,
          cellRendererParams: {
            editRecord: function (store: StoreModel) {
              self.editRecord(store);
            },
            viewRecordDetails: function (store: StoreModel) {
              self.viewRecordDetails(store);
            },
            actions: ['view', 'edit']
          },
        },
      ];
    },
    rowClicked(id: number) {
      this.$router.push(`/ecommerce/storeAdmins/${id}`);
    },
    viewRecordDetails(record: StoreModel) {
      this.popupActiveDetails = true;
      this.store = record;
    },
    initFileUpload() {
      (this.$refs.fileUpload as any)?.initValidation();
    },
    fileUploaded(file: string) {
      this.store.cover_url = file;
    },
    fileDeleted() {
      this.store.cover_url = '';
    },
    rerender() {
      this.gridApi?.setRowData(this.getStores);
    },
    openPopup() {
      (this.$refs.validationObserver as any).reset();
      this.popupActive = true;
    },
    createRecord() {
      utilities.initObjectMembers(this.store);
      this.dialogTitle = 'اضافة متجر';
      this.openPopup();
    },
    editRecord(store: StoreModel) {
      this.dialogTitle = 'تعديل متجر';
      Object.assign(this.store, store);
      this.openPopup();
    },
    async onSubmit() {
      this.isLoading = true;
      if (this.store.id !== undefined) {
        await this.editStore(this.store);
      } else {
        await this.addStore(this.store);
      }
      this.rerender();
      this.initFileUpload();
      this.popupActive = false;
      this.isLoading = false;
    },
  },
  async created() {
    this.gridOptions = {};
    this.gridOptions.columnDefs = this.createColDefs();
    await Promise.all([this.fetchStores(), this.fetchMarkets()]);
    this.isLoading = false;
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
  }
});
